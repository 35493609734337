<template>
    <div class="layout" @click="isDisplay = false">
        <!-- 导航栏 -->
        <div class="topbar">
            <div class="container">
                <ul class="nav">
                    <li>
                        <router-link :to="{ name: 'study', params: {} }"><i class="iconfont icon-home"></i>首页</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'index', params: {} }"><i class="iconfont icon-viewcomfortable"></i>学习中心</router-link>
                    </li>
                    <li>
                        <a href="#"><i class="iconfont icon-me1"></i>用户中心</a>
                    </li>
                    <li>
                        <a href="#" @click="logout"><i class="iconfont icon-planefill"></i>退出</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 心铭远单词速记title -->
        <div class="header-box">
            <div class="container" style="padding: 0">
                <div class="header clearfix">
                    <div class="pull-left" style="width: 15%">
                        <div class="logo">
                            <a href="#"><img src="~@/assets/images/study/logohw.png" alt="" /></a>
                        </div>
                    </div>
                    <div class="pull-right" style="width: 85%">
                        <div class="center-menubar" id="center-menubar">
                            <ul>
                                <li id="vocabularyBook" style="cursor: pointer">
                                    <router-link :to="{ name: 'papers_spacial', params: {} }">
                                        <span class="sticker radius"
                                            style="background-color: rgb(153, 102, 102)"></span>
                                        <span class="font16" style="line-height: 32px">专项训练</span><br />
                                        <span class="font12" style="float: right">
                                            <span class="fontDRed">●</span>
                                            &nbsp;攻克难关
                                        </span>
                                    </router-link>
                                </li>
                                <li id="reports" style="cursor: pointer">
                                    <router-link :to="{ name: 'papers_paper_report', params: {} }">
                                        <span class="sticker radius" style="background-color: rgb(51, 102, 153)"></span>
                                        <span class="font16" style="line-height: 32px">学习报告</span><br />
                                        <span class="font12" style="float: right">
                                            <span class="fontDRed">●</span>&nbsp;数据统计
                                        </span>
                                    </router-link>
                                </li>
                                <li id="wronwordBook" style="cursor: pointer">
                                    <router-link :to="{ name: 'papers_paper_wrong' }">
                                        <span class="sticker radius" style="background-color: rgb(255, 0, 110)"></span>
                                        <span class="font16" style="line-height: 32px">我的错题</span><br />
                                        <span class="font12" style="float: right">
                                            <span class="fontDRed">●</span>
                                            &nbsp;智学弱项
                                        </span>
                                    </router-link>
                                </li>
                                <li id="vocabularyBook" style="cursor: pointer">
                                    <router-link :to="{ name: 'papers_runking' }">
                                        <span class="sticker radius" style="background-color: rgb(204, 0, 51)"></span>
                                        <span class="font16" style="line-height: 32px">排行榜</span><br />
                                        <span class="font12" style="float: right">
                                            <span class="fontDRed">●</span>
                                            &nbsp;我的荣耀
                                        </span>
                                    </router-link>
                                </li>
                                <li id="independentTest" style="border-right-style: none;cursor: pointer">
                                    <a href="#" @click="shopVisible = true"><span class="sticker radius"
                                            style="background-color: rgb(51, 153, 51)"></span>
                                        <span class="font16" style="line-height: 32px;">积分商城</span><br />
                                        <span class="font12" style="float: right">
                                            <span class="fontDRed">●</span>
                                            &nbsp;大有钱途
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="count">
                <div class="count-time">
                    <span class="count-time__info">{{ jsq_times }}</span>
                </div>
            </div>
        </div>

        <router-view></router-view>

        <!--        <router-view></router-view>-->

        <!-- <spacial></spacial>
        <light-music class="light-music" ref="drag" />-->

        <el-dialog class="shop-dialog" :visible.sync="shopVisible" v-if="shopVisible" width="1000px">
            <div class="close-btn" @click="shopVisible = false">
                <i class="fa fa-close"></i>
            </div>
            <span class="shopping-title">积分商城</span>
            <shopping class="shopping" />
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";

import paperResult from "@/views/papers/paper_result"
import paperAnalysis from "@/views/papers/paper_analysis"
import userAvatar from "@/views/study/UserAvatar";
import shopping from "@/views/study/shopping";

export default {
    components: {
        paperResult,
        paperAnalysis,
        userAvatar,
        shopping
    },
    data() {
        return {

            specialList: [],
            specialTotal: 0,
            specialHas: 10,
            specialIndex: 0,

            textShow: false,
            avatarVisible: false,
            eyes: false,
            score: 60,
            scoreWidth: 10,
            shijuanList: [],
            search: '',
            border: true,
            getIndex: '',
            shopVisible: false,
            currentPage: 1,
            inside: true,
            isDisplay: false,
            pColor: "#7fb8f2",
            value: true,
            pWidth: 25,
            dialogVisible: false,
            jsq_times: "00.00.00",
            activeName: 'first',
            isSlider: true,
            type: 1,
            pageSize: 7,
            page: 1,
            rankList: [],
            all_count: 0,
            userRank: {},
            videoIndex: 0,
            videoSrc: [
                { src: '../assets/video/girl.avi' },
                { src: '../assets/video/boy.avi' }
            ],
            result: {},
            kaoshiDetailData: {},
            resultDialogVisible: false,
            analysisDialogVisible: false,
        };
    },
    created() {
        this.jsq();
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
        jinDu: function () {
            // `this` 指向 vm 实例
            var jd = 0;
            if (this.user.level != undefined) {
                var baoshi = this.user.baoshi || 0;
                var upper_exp = this.user.level.end_inter;
                var lower_exp = this.user.level.start_inter;
                var all = upper_exp - lower_exp;
                var j = baoshi - lower_exp;
                //console.log("upper_exp：" + upper_exp + "lower_exp：" + lower_exp);
                if (all <= 0) {
                    jd = 0;
                } else if (j > all) {
                    jd = 100;
                } else {
                    jd = Math.round((j / all) * 100);
                }
            }
            /*if(upper_exp>0){
                        jd = Math.round((lower_exp / upper_exp) * 100);
                      }
                      if(jd<0){
                        jd = 0;
                      }else if(jd>100){
                        jd = 100;
                      }*/
            return jd;
        },
        percent: function () {
            let percents;
            percents = this.specialHas / this.specialTotal * 100;
            if (percents > 100) {
                percents = 100
            } else if (percents < 0) {
                percents = 0
            } else {
                return percents
            }
        }
    },
    mounted() {
        //let scores = document.querySelector('.el-progress__text');
        //scores.innerHTML = scores.innerHTML.replace('%', '');
        this.getEchartsData()
        // this.getEchartsDatas()
    },
    methods: {
        changeEyes() {
            this.eyes = !this.eyes;
        },
        getShijuanList() {
            let data = {};
            data.token = this.token;
            //console.log(data);
            this.axios.post("/api/exam/shijuan_list", data).then((res) => {
                //console.log(res);
                if (res.data.status_code == 200) {
                    let data = res.data.data;
                    this.shijuanList = data.list;
                    //console.log(data.list);
                } else {
                    this.$message(res.data.msg);
                }
            })
                .catch((err) => {
                    //this.loading = false;
                });
        },
        toTestCenter(item) {
            if (item.kaoshi_count > 0) {
                this.getKaoshiDetail(item.id);
                //this.$message('您已考过该试卷，不能重复考试');
            } else {
                this.$router.push({
                    name: "papers_test_papers",
                    query: {
                        id: item.id,
                    },
                });
            }

        },
        navEvent(index) {
            //this.$store.commit('setPage', index);
        },
        schoolEvent(type) {
            this.type = type;
            this.page = 1;
            this.getList();

        },
        handleSizeChange(val) {
            //console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getList();

            //console.log(`当前页: ${val}`);
        },
        handleClick(tab, event) {
            //console.log(tab, event);
        },
        format(percentage) {
            return `${percentage}`;
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => {
                });
        },
        logout() {
            //console.log("退出登陆");
            this.axios
                .post("/api/auth/logout", { token: this.token, get_type: this.type, })
                .then((res) => {
                })
                .catch((err) => {
                    this.loading = false;
                });
            this.$store.commit("logout");
            this.$router.push({ name: "login" });
        },
        jsq() {
            //点击开始建 开始计数
            let that = this;
            var uid = this.user.id;
            //console.log("uid:" + uid);
            var s = window.sessionStorage.getItem("jqx_count" + uid);
            var count = 0;
            if (s) {
                //console.log("sessionStorage:" + s);
                count = s;
            }
            var timer = null; //timer变量记录定时器setInterval的返回值
            timer = setInterval(function () {
                count++;
                window.sessionStorage.setItem("jqx_count" + uid, count);
                //console.log(count);
                // 需要改变页面上时分秒的值
                //console.log($("id_S"));
                var times_str = "";
                times_str += that.showNum(parseInt(count / 60 / 60)) + ":";
                times_str += that.showNum(parseInt(count / 60) % 60) + ":";
                times_str += that.showNum(count % 60);
                //console.log(times_str);
                that.jsq_times = times_str;
            }, 1000);
        },
        //封装一个处理单位数字的函数
        showNum(num) {
            if (num < 10) {
                return "0" + num;
            }
            return num;
        },
        getEchartsData() {
            const chart = this.$refs.chart
            if (chart) {
                const myChart = this.$echarts.init(chart)
                const option = {
                    legend: {
                        top: 'bottom'
                    },
                    color: [
                        '#66c79a',
                        '#f2c454',
                        '#de82d1',
                        '#5aa8f6'
                    ],
                    series: [
                        {
                            type: 'pie',
                            data: [
                                {
                                    value: 200,
                                    name: '听力'
                                },
                                {
                                    value: 250,
                                    name: '语言知识运用'
                                },
                                {
                                    value: 3000,
                                    name: '阅读理解'
                                },
                                {
                                    value: 1380,
                                    name: '书面表达'
                                }
                            ],
                            roseType: 'area',
                            radius: ['20%', '80%'],
                            itemStyle: {
                                borderRadius: 6,
                                borderColor: '#fff',
                                borderWidth: 2,
                            }
                        }
                    ]
                }
                myChart.setOption(option)
                window.addEventListener('resize', function () {
                    myChart.resize()
                })
                this.$on('hook:destroyed', () => {
                    window.removeEventListener("resize", function () {
                        myChart.resize();
                    })
                })
            }
        },
        getEchartsDatas() {
            const chart = this.$refs.chart
            if (chart) {
                const myChart = this.$echarts.init(chart)
                const option = {
                    legend: {
                        top: 'bottom'
                    },
                    color: [
                        '#66c79a',
                        '#f2c454',
                    ],
                    series: [
                        {
                            type: 'pie',
                            data: [
                                {
                                    value: 400,
                                    name: '听力'
                                },
                                {
                                    value: 1548,
                                    name: '语言知识运用'
                                },
                            ],
                            roseType: 'area',
                            radius: ['20%', '80%'],
                            itemStyle: {
                                borderRadius: 6,
                                borderColor: '#fff',
                                borderWidth: 2
                            }
                        }
                    ]
                }
                myChart.setOption(option)
                window.addEventListener('resize', function () {
                    myChart.resize()
                })
                this.$on('hook:destroyed', () => {
                    window.removeEventListener("resize", function () {
                        myChart.resize();
                    })
                })
            }
        },
        // 排行榜 点击改变行内样式
        handle(row) {
            //console.log(row);
            //console.log(row.id);
            this.getIndex = row.id - 1;
        },
        isChecked({ rowIndex }) {
            //console.log(rowIndex)
            if ((this.getIndex) === rowIndex) {
                return {
                    "color": '#278df2'
                }
            }
        },
        resultClose() {
            this.resultDialogVisible = false;
            this.analysisDialogVisible = true;
        },
        analysisClose() {
            this.analysisDialogVisible = false;
        },
        getKaoshiDetail(id) {
            let data = {};
            data.id = id;
            data.token = this.token;
            this.axios.post("/api/exam/detail", data).then((res) => {
                //console.log(res);
                if (res.data.status_code == 200) {
                    this.kaoshiDetailData = res.data.data;
                    //this.shijuanList = data.list;
                    //console.log(data);
                    this.showResult();

                } else {
                    this.$message(res.data.msg);
                }
            })
                .catch((err) => {
                    //this.loading = false;
                });
        },
        showResult() {
            let result = this.result;
            result.yongshi_time = this.kaoshiDetailData.kaoshiInfo.yongshi_time;
            result.yongshi = this.kaoshiDetailData.kaoshiInfo.yongshi;
            result.shijuanInfo = this.kaoshiDetailData.info;
            result.kaoshiInfo = this.kaoshiDetailData.kaoshiSetInfo;
            result.score = this.kaoshiDetailData.kaoshiInfo.score;
            result.stageScoreArr = this.kaoshiDetailData.kaoshiInfo.stage_score;
            this.resultDialogVisible = true;
        },
        getUserInfo() {
            let than = this;
            this.loading = true;
            //let danyuan_ids = this.danyuanCheckStr;
            let params = {
                token: this.token,
            };
            //console.log(params);
            this.axios
                .post("/api/user/get_user_info", params)
                .then((res) => {
                    let data = res.data;
                    //console.log("get_user_info");
                    //console.log(data, "用户信息");
                    if (data.status_code == 200) {
                        //console.log(data.data);
                        this.$store.commit("setUser", data.data);
                    } else if (res.data.status_code == 202) {
                    } else {
                        //this.$message(res.data.msg)
                    }
                })
                .catch((err) => {
                    //this.loading = false
                });
        },
    },
};
</script>

<style lang="scss">
@import "~@/assets/css/ai_word.scss";
@import "~@/assets/css/paper_list.scss";

@media screen and (min-width: 375px) and (max-width: 767px) {
    html {
        font-size: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    html {
        font-size: 63px !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    html {
        font-size: 70px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
    html {
        font-size: 80px;
    }
}

//@media screen and (min-width: 1366px) and (max-width: 1440px) {
//    font-size: 100px;
//}

@media screen and(min-width: 1441px) and (max-width: 1920px) {
    html {
        font-size: 100px;
    }
}

@media screen and (min-width: 1920px) and (max-width: 7680px) {
    html {
        font-size: 100px;
    }
}

@media (min-width: 1200px) {

    .container,
    .header {
        width: 1170px;
    }
}

.light-music {
    position: fixed;
    right: 0;
    bottom: 0;
}

.girl {
    position: fixed;
    left: 0;
    bottom: 0;
}

.el-loading-mask .el-loading-spinner {
    width: 300px;
    padding: 20px;
    left: 50%;
    margin-left: -150px;

}

.el-loading-mask .el-icon-loading {
    font-size: 50px;
    color: #F2A454;
}

.el-loading-mask .el-loading-text {
    font-size: 20px;
    color: #ffffff;
}

@keyframes spin {

    0%,
    15% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader {
    _background: #000;
    _background: -webkit-radial-gradient(#222, #000);
    _background: radial-gradient(#222, #000);
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99999;

    &-inner {
        bottom: 0;
        height: 60px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 100px;
    }
}


.kaoshiResultDialog.el-dialog {
    .el-dialog__header {
        display: none;
    }
}
</style>
